import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const ExistingUserRegisterForFamilyTrustEngagement = () => {
  return (
    <Layout>
      <Seo
        title="How Can an Existing User Register for Family Trust Engagement?"
        description={`In family trust engagement, users will find clear, step-by-step instructions for gaining verification status after submitting all required details via the Synkli App.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`existing-user-register-for-family-trust-engagement`} />
    </Layout>
  )
}

export default ExistingUserRegisterForFamilyTrustEngagement
